import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Paper, Typography, Input, InputAdornment, TextField, Button, createStyles, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useLocation } from 'react-router-dom';
// import { useGetWebinarQuery, useRefreshtokenMutation, useupdateSpeakerMutation, usedeleteSpeakerMutation } from '../../Features/authActions.js';
import { useGetSpeakersQuery, useUpdateSpeakerMutation, useDeleteSpeakerMutation, useEnableSpeakerMutation } from '../../Features/authActions.js';
import { useSelector, useDispatch } from "react-redux"
import { getRefreshToken } from '../../utils/authCheck.js';
import { useAuth } from '../../Hooks/useAuth.js';
import { Switch } from '@mui/material';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
// import { useAuth } from '../../Hooks/useAuth.js';

export default function Webinar() {
    const [selected, selectedRow] = useState([]);
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [skip, setSkip] = useState(false)
    const [speakersData, setSpeakersData] = useState([])
    const queryObj = {}
    location.search.split('?').forEach(query => {
      if(!query?.length) {
        return
      }
      const qr = query.split('=')
      queryObj[qr[0]] = qr[1]
    })
    const { token } = useAuth();
    console.log(token, "inside speaker dm" , new Date())
    const { data, isLoading,isSuccess, isError, refetch } = useGetSpeakersQuery({ token: token }, { skip: skip});
    const [updateSpeaker, { isLoading: updateLoading, isError: updateError, isSuccess: updateSuccess }] = useEnableSpeakerMutation();
    const [deleteSpeaker, { isLoading: deleteLoading, isError: deleteError, isSuccess: deleteSuccess }] = useDeleteSpeakerMutation();
    useEffect(()=> {
        if(queryObj?.refetch == true){
          refetch();
        }
      },[queryObj])
    useEffect(() => {
        if(token?.length > 0){
            refetch();
        }
    }, [token])
    useEffect(() => {
  
        if( isSuccess && data?.data?.length > 0){
        let temp =  data.data.map((item) => {
            return {
                id: item.id,
                name: item.name,
                email: item.email,
                phone: item.phoneNumber,
                Designation: item.designation,
                Bio: item.bio,
                Experience : item.expInYears,
                profile : item.profileImg,
                enabled: item.status
            }
        } )

        setSpeakersData(temp)
    }
    }, [data, isSuccess])

    useEffect(() => {
        if (updateSuccess) {
            toast.success('speaker updated successfully');
        }
        else if (updateError) {
            toast.error('Something went wrong, please try again later');
        }
        else if (deleteSuccess) {
            if(selected.length > 0){
               setSpeakersData(speakersData.filter((item) => !selected.includes(item.id)))
            }
            toast.success('speaker deleted successfully');
        }
        else if (deleteError) {
            toast.error('Something went wrong, please try again later');
        }
    }, [updateSuccess, updateError, deleteSuccess, deleteError])
    const handleEnable = (params) => {
        let data = {
            id: params.id,
            status: !params.enabled
        }
        updateSpeaker({ token: token, payload: data })
        setTimeout(() => {
            refetch();
        }, 1000);
    }

    const handleEdit = (params) => {
        Navigate(`/dashboard/forms/speaker/edit/${params.id}`)
    }
    const handleSelectedRows = (data) => {
        selectedRow(data);
    }
    const handleDelete = (e) => {
        e.preventDefault();
        deleteSpeaker({ token: token, payload: selected })
        setTimeout(() => {
            refetch();
        });
    }
    const columns = [
        // { field: 'id', headerName: 'ID', width: 300 },
        {
            field: 'enabled',
            headerName: 'Enabled',
            width: 100,
            renderCell: (params) => {
                return <Switch checked={params.row.enabled} onChange={() => handleEnable(params.row)} />
            }
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => {
                return <Button variant="contained" color="primary" size='small'
                    sx={{ width: '5px' }} onClick={() => handleEdit(params)}
                >
                    <EditIcon />
                </Button>
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            editable: true,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
            editable: true,
        },
        {
            field: 'phone',
            headerName: 'PhoneNumber',
            width: 150
        },
        //, {
        //     field: 'Bio',
        //     headerName: 'bio',
        //     width: 200,
        //     renderCell : function renderCell(params){
        //         return <span>
        //             {parse(params.row.Bio)}
        //         </span>
        //     }
        // }, 
        {
            field: 'Experience',
            headerName: 'expInYears',
            width: 150,
            renderCell  : function renderCell(params){
                return <span style= {{ margin : '0px 50px'}}>
                    {(params.row.Experience)}
                </span>
            }
        },
        {
            field: 'Profile',
            headerName: 'profileImg',
            width: 150,
            renderCell: function renderCell(params) {
                return <img src={params.row.profile} alt="" style={{ width: '50px', height: '40px', borderRadius: '50%' }} />
            }
        },
        {
            field: 'Designation',
            headerName: 'designation',
            width: 200
        },
        
    ];
    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Grid item container
                        sx={{ justifyContent: 'space-between' }} >
                        <Grid item >
                            <Typography variant="h6" gutterBottom component="div">
                                Speakers List
                            </Typography>
                        </Grid>
                        {/* <Grid sx={{ border: '1px solid red' }}>

          </Grid>  */}
                        <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained" color="primary"
                                onClick={() => Navigate('/dashboard/forms/speaker')}
                            >
                                <AddIcon />
                            </Button>
                            {/* </Grid>
          <Grid item xs={12} sm={3}> */}
                            <Button variant="contained"
                                color='error'
                                onClick={(e) => handleDelete(e)}
                            >
                                <DeleteIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    {speakersData && <DataGrid
                        rows={speakersData}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 15,
                                },
                            }
                        }}
                        onRowSelectionModelChange={handleSelectedRows}
                        disableColumnSelector
                        pageSizeOptions={[15]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        // write style to make header cell background color and bold
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold',
                                color: 'white',
                                fontSize: '1rem'
                            },
                            '& .MuiDataGrid-menuIconButton': {
                                // make icon color white
                                color: 'white',
                                backgroundColor: 'transparent',
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            },
                            '& .MuiDataGrid-menuIcon': {
                                // make icon color white
                                color: 'white',
                                backgroundColor: 'transparent',
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            },
                            // chage color of sort icon color to white
                            '& .MuiDataGrid-sortIcon': {
                                color: 'white'
                            },
                            // remove cell border on  click
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none'
                            },
                            // remove hide column and manage columns
                            '& .MuiDataGrid-columnHeader' : {
                                backgroundColor : 'transparent'
                            },
                            height: '80vh',
                            "& .MuiDataGrid-virtualScrollerRenderZone": {
                                "& .MuiDataGrid-row": {
                                  "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                }
                              }
                              ,
                              "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: '#3f51b5',
                                color: "white",
                                fontSize: 16
                              }
                        }}
                    />}
                </Grid>
            </Grid>
        </>
    );
}

