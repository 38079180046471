import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Grid, Typography, Button, Paper } from '@mui/material'
import CommonTextField from '../../Components/common/CommonTextField'
import { CommonReactQuill } from '../../Components/CommonReactQuill'
import { v4 as uuidv4 } from 'uuid';
import { useCreateSpeakerMutation } from '../../Features/authActions'
import { toast } from 'react-toastify'
import { useAuth, useJwt } from '../../Hooks/useAuth'
import { useLocation, useNavigate} from 'react-router-dom'

const fromLyout = {
  "name": "Karla Brandau",
  "email": "Nkris@gmail.com",
  "phone": "9110708020",
  "bio": "this is the initial bio of the speaker",
  "expInYears": 5,
  "profileImg": "https://online-training-image.s3.amazonaws.com/61e1d7c1-84f8-4750-a166-cf3b82045d30.jpg",
  "designation": "Senior Developer"
}
const SpeakerForm = () => {
  const navigate = useNavigate()
  const navigatePath = '/dashboard/datamanager/speaker?refetch=true'
  const { control, handleSubmit, watch, setValue } = useForm()
  const { token } = useAuth()
  const { isExpired } = useJwt(token)
  const [file, setFile] = React.useState(null)
  const [createSpeaker, { data: createSpeakerData, isSuccess: createSpeakerSuccess, isLoading: createSpeakerLoading, isError: createSpeakerError }] = useCreateSpeakerMutation()
  const watchAllFields = watch()
  const handleUpload = (e) => {
    setFile(e.target.files[0])
  }
  const randomId = () => {
    return uuidv4()
  }
  const onSubmit = (data) => {
    if(isExpired){
      toast.error('Session Expired')
      return
    }
    const formData = new FormData();
    formData.append('id', data.id);
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('phoneNumber', data.phone);
    formData.append('bio', data.bio);
    formData.append('expInYears', data.expInYears);
    formData.append('designation', data.designation);
    formData.append('profileImg', file);
    const headers = new Headers();
    headers.append('Content-Type', 'multipart/form-data');
    createSpeaker({ token: token, payload: formData, headers: headers })
  }
  useEffect(() => {
    if (createSpeakerSuccess) {
      toast.success('Speaker Created Successfully')
      navigate(navigatePath)
    }
    else if (createSpeakerError) {
      toast.error('Something went wrong')
    }
  }, [createSpeakerSuccess, createSpeakerError])

  return (
    <Paper
      elevation={4}
      sx={{
        p: 4,
        margin: "auto",
      }}
      style={{ width: "90%", }}
    >
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
      >
        <Typography variant='h5' sx={{ textAlign: 'center', margin: 'auto' }}>Create Speaker</Typography>
        <Grid item container xs={12} lg={12} md={12} spacing={2} sx={{ alignItems: 'center' }} >
          <Grid item xs={12} lg={6} md={6}>
            <CommonTextField
              name='id'
              control={control}
              label='Id'
              fullWidth
              defaultValue={randomId()}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6}>
            <CommonTextField
              name='name'
              control={control}
              label='Name'
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} lg={12} md={12} spacing={2} sx={{ alignItems: 'center' }} >

          <Grid item xs={12} lg={6} md={6}>
            <CommonTextField
              name='email'
              control={control}
              type='email'
              label='Email'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6}>
            <CommonTextField
              name='phone'
              control={control}
              label='Phone'
              type='number'
              InputProps={{
                inputProps: {
                  readOnly: false,
                },
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} lg={12} md={12} spacing={2} sx={{ alignItems: 'center' }} >
          <Grid item xs={12} lg={6} md={6}>
            <CommonTextField
              name='expInYears'
              control={control}
              type='number'
              label='Experience In Years'
              InputProps={{
                inputProps: {
                  readOnly: false,
                },
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6}>
            <CommonTextField
              name='designation'
              control={control}
              label='Designation'
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} lg={12} md={12} spacing={2} sx={{ alignItems: 'center' }} >
          <Grid item xs={12} lg={12} md={12}>
            <CommonReactQuill
              name='bio'
              control={control}
              label='Bio'
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} lg={12} md={12} spacing={2} sx={{ alignItems: 'center' }} >
          <Grid item xs={12} lg={12} md={12}>
            <input type="file" name="profileImg" onChange={(e) => handleUpload(e)} />
          </Grid>
          <Grid item xs={12} lg={12} md={12}>
            <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Submit</Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>

  )
}

export default SpeakerForm